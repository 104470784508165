import React, { Component } from 'react';
import { HashRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import Loadable from 'react-loadable';
import './App.css';
import { isLoggedIn } from './utils/AuthService';

const Login = Loadable({
  loader: () => import('./components/Login'),
  loading() {
    return ''
  }
});
const Logout = Loadable({
  loader: () => import('./components/Logout'),
  loading() {
    return ''
  }
});
const ForgotPassword = Loadable({
  loader: () => import('./components/ForgotPassword'),
  loading() {
    return ''
  }
});
const ResetPassword = Loadable({
  loader: () => import('./components/ResetPassword'),
  loading() {
    return ''
  }
});
const ChangePassword = Loadable({
  loader: () => import('./components/ChangePassword'),
  loading() {
    return ''
  }
});
const Failedauth = Loadable({
  loader: () => import('./components/Failedauth'),
  loading() {
    return ''
  }
});
const Registration = Loadable({
  loader: () => import('./components/Registration'),
  loading() {
    return ''
  }
});


const ProfileView = Loadable({
  loader: () => import('./components/ProfileView'),
  loading() {
    return ''
  }
});

const EditProfile = Loadable({
  loader: () => import('./components/EditProfile'),
  loading() {
    return ''
  }
});

const Events = Loadable({
  loader: () => import('./components/Events'),
  loading() {
    return ''
  }
});

const Reader = Loadable({
  loader: () => import('./components/Reader'),
  loading: () => {
    return ''
  }
});

const RedeemPoints = Loadable({
  loader: () => import('./components/RedeemPoints'),
  loading() {
    return ''
  }
});

const BizOpportunity = Loadable({
  loader: () => import('./components/BizOpportunity'),
  loading() {
    return ''
  }
});

const AttendingEvents = Loadable({
  loader: () => import('./components/AttendingEvents'),
  loading() {
    return ''
  }
});

const VerifiedEvents = Loadable({
  loader: () => import('./components/VerifiedEvents'),
  loading() {
    return ''
  }
});

const Notifications = Loadable({
  loader: () => import('./components/Notifications'),
  loading() {
    return ''
  }
});

const Home = Loadable({
  loader: () => import('./components/Home'),
  loading() {
    return ''
  }
});

const SubMenuPage = Loadable({
  loader: () => import('./components/SubMenuPage'),
  loading() {
    return ''
  }
});

const Index = Loadable({
  loader: () => import('./components/Index'),
  loading() {
    return ''
  }
});

const ComingSoon = Loadable({
  loader: () => import('./components/ComingSoon'),
  loading() {
    return ''
  }
});


class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      auth_message: 'Please sign in to continue..',
      menu_pages: '',
      redirect: false
    }

  }

  render() {

    return (
      <React.Fragment>
        <Router>
          <Switch>

            <Route exact path='/login' component={Login} />
            <Route
              exact path="/" render={() => (
                isLoggedIn() ? (
                  <Redirect
                    to={{
                      pathname: '/home'
                    }}
                  />
                ) : (
                    <Login />
                  )
              )}
            />

          <Route
              exact path="/index" render={() => (
                    <Index />
                  )
                }
            />
          
            <Route
              exact path="/forgot-password" render={() => (
                isLoggedIn() ? (
                  <Redirect
                    to={{
                      pathname: '/profile'
                    }}
                  />
                ) : (
                    <ForgotPassword />
                  )
              )}
            />
            <Route
              exact path="/reset-password/:token" render={(props) => (
                isLoggedIn() ? (
                  <Redirect
                    to={{
                      pathname: '/profile'
                    }}
                  />
                ) : (
                    <ResetPassword {...props} />
                  )
              )}
            />

            <Route
              exact path="/change-password" render={() => (
                isLoggedIn() ? (
                  <ChangePassword />
                ) : (
                    <Failedauth />
                  )
              )}
            />

            <Route
              exact path="/logout" render={() => (
                isLoggedIn() ? (
                  <Logout />
                ) : (
                    <Failedauth />
                  )
              )}
            />
            <Route
              exact path="/registration" render={() => (
                isLoggedIn() ? (
                  <Redirect
                    to={{
                      pathname: '/profile'
                    }}
                  />
                ) : (
                    <Registration />
                  )
              )}
            />
            <Route
              exact path="/profile" render={() => (
                isLoggedIn() ? (

                  <ProfileView />
                ) : (
                    <Failedauth />
                  )

              )}
            />
            <Route
              exact path="/edit-profile" render={() => (
                isLoggedIn() ? (
                  <EditProfile />
                ) : (
                    <Failedauth />
                  )

              )}
            />
            <Route
              exact path="/events/upcoming" render={() => (
                isLoggedIn() ? (
                  <Events />
                ) : (
                    <Failedauth />
                  )

              )}
            />
            <Route
              exact path="auth/check-reset-password-link" render={() => (
                isLoggedIn() ? (
                  <ProfileView />
                ) : (
                    <Failedauth />
                  )

              )}
            />
            <Route
              exact path="/reader" render={() => (
                isLoggedIn() ? (
                  <Reader />
                ) : (
                    <Failedauth />
                  )
              )}
            />
            <Route
              exact path="/redeem" render={() => (
                isLoggedIn() ? (
                  <RedeemPoints />
                ) : (
                    <Failedauth />
                  )
              )}
            />
            <Route
              exact path="/biz-opportunities" render={() => (
                isLoggedIn() ? (
                  <BizOpportunity />
                ) : (
                    <Failedauth />
                  )
              )}
            />
            <Route
              exact path="/events/attending" render={() => (
                isLoggedIn() ? (
                  <AttendingEvents />
                ) : (
                    <Failedauth />
                  )
              )}
            />
            <Route
              exact path="/events/attended" render={() => (
                isLoggedIn() ? (
                  <VerifiedEvents />
                ) : (
                    <Failedauth />
                  )
              )}
            />
            <Route
              exact path="/notifications" render={() => (
                isLoggedIn() ? (
                  <Notifications />
                ) : (
                    <Failedauth />
                  )
              )}
            />
            
            <Route exact path="/page/:subMenuPath" render={(props) => (
              isLoggedIn() ? (
              <SubMenuPage key={props.match.params.subMenuPath} {...props} />)
              : 
              (<Failedauth />)
            )} 
            />
            <Route
              exact path="/home" render={() => (
                isLoggedIn() ? (
                  <Home />
                ) : (
                    <Failedauth />
                  )
              )}
            />
             <Route
              exact path="/coming-soon" render={() => (
                isLoggedIn() ? (
                  <ComingSoon />
                ) : (
                    <Failedauth />
                  )
              )}
            />
          </Switch>

        </Router>
      </React.Fragment>
    );
  }
}

export default App;
