import axios from 'axios';
import { API_URL } from '../utils/Config';


export function login(user) {
  localStorage.setItem('user', JSON.stringify(user));
}

export function logout() {
  localStorage.removeItem('user');
  localStorage.clear();
  window.location.replace("/");
}

export function isLoggedIn() {
  if (localStorage.getItem('user') === null) {
    return false;
  }
  return true;
}


